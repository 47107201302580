import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import withTranslate from '../../hoc/with-translate';

import AvatarImage from '../avatar-image';

const UserAvatarImage = ({ t, className, user = {}, ...props }) => (
  <AvatarImage
    ariaLabel={t('aria-label.authors-picture')}
    className={className}
    image={user.image}
    {...omit(props, 'dispatch')}
  />
);

hoistNonReactStatics(UserAvatarImage, AvatarImage);

UserAvatarImage.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
  type: PropTypes.string,
  t: PropTypes.func,
};

export default withTranslate(UserAvatarImage);
